<template>
  <div>
    <NPCommonLoadingSpinner v-if="isLoading" class="py-9 md:py-20" />
    <template v-else>
      <FormsLoginForm :error-message="error" @submit="callLogin" />

      <div class="mt-4">
        <button @click.prevent="openRegister">
          Noch kein Konto? Registrieren
        </button>

        <p>
          <button @click.prevent="openForgetPassword">Passwort vergessen?</button>
        </p>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
defineEmits(['close'])

const { openModal, closeModal } = useModal()

function openRegister() {
  openModal('Register')
}

function openForgetPassword() {
  openModal('ForgetPassword')
}

const { login } = useAuth()

const error = ref('')
const isLoading = ref(false)

async function callLogin(emailOrUsername: string, password: string) {
  error.value = ''

  let result = false

  try {
    isLoading.value = true
    result = await login(emailOrUsername, password)
    error.value = ''
  }
  catch (err) {
    error.value = err.message
  }
  finally {
    isLoading.value = false
  }

  if (error.value === '' && result) {
    closeModal()
  }
}
</script>
